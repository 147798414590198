//- Logo
//- ----------------------------------------------

// Header Logo
.header-logo {

	// Logo: Image
	&--img {
		z-index: 1;
		transition: .3s all ease;

		.site-wrapper--has-menu-overlay & {
			opacity: 0;
		}

		.site-layout--landing & {
			opacity: 1;
			transition: .7s .6s all cubic-bezier(0.86, 0, 0.07, 1);
		}

		.preloader-is--active .site-layout--landing & {
			opacity: 0;
			transform: translateY(-50%) scale(1.5);
		}

		.site-header--top &,
		.site-header--bottom & {
			position: absolute;
			left: 28px;

			max-width: 50px;

			@include media-breakpoint-down(sm) {
				left: 20px;

				img {
					max-width: 100%;
					height: auto;
				}
			}

			@media (min-width: $xxl-breakpoint) {
				left: 46px;
			}

			@media (min-width:600px) {
				max-width: 60px;
			}
		}

		.site-header--top & {
			top: 4px;

			@include media-breakpoint-down(md) {
				max-width: 60px;
			}


			@media (min-width: $xxl-breakpoint) {
				top: 14px;
			}

			@media (min-width: 600px) {
				bottom: 60px !important;
			}

			img {

				@include media-breakpoint-up(lg) {
					max-width: 60px;
				}
			}
		}

		.site-header--bottom & {
			bottom: 12px;

			@include media-breakpoint-down(md) {
				bottom: 6px;
			}

			@media (min-width: $xxl-breakpoint) {
				bottom: 20px;
			}

			html.touch & {
				@include media-breakpoint-down(sm) {
					top: 36px;
					bottom: auto;
				}

				@media (min-width: 600px) {
					top: auto;
					bottom: -40px;
				}
			}


		}

		.site-header--landing & {
			z-index: 3;
			padding-left: 48px;
			padding-right: 48px;

			@include media-breakpoint-up(lg) {
				padding-left: 86px;
				padding-right: 86px;
			}
		}

		img {
			max-width: 140px;

			@media (min-width: 992px) and (max-width: 1919px) {
				.site-header--landing & {
					max-width: 18.229167vh;
				}
			}

			@media (min-width: $xxl-breakpoint) {
				max-width: 200px;
			}

			@media (min-width:600px) {
				max-width: 90px;
			}

			@media (min-width: 1200px) {
				max-width: 150px;
			}
		}
	}

	// Logo: Text
	&--txt {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-left: 12px;

		@include media-breakpoint-up(md) {
			padding-left: 34px;
		}

		> a {
			color: $header-logo-font-color;
		}
	}

		.header-logo__inner {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.header-logo__heading {
			font-family: $header-logo-font-family;
			font-size: 1em;
			line-height: 1em;
			letter-spacing: $header-logo-letter-spacing;
			text-transform: $header-logo-text-transform;
			color: $header-logo-font-color;
			font-weight: $header-logo-font-weight;
			margin: 0;
			flex-basis: 100%;

			@include media-breakpoint-up(md) {
				font-size: $header-logo-font-size;
			}

			.highlight {
				color: $primary;
			}
		}
		.header-logo__tagline {
			font-family: $header-tagline-font-family;
			font-size: $header-tagline-font-size;
			line-height: 1em;
			letter-spacing: $header-tagline-letter-spacing;
			text-transform: $header-tagline-text-transform;
			color: $header-tagline-font-color;
			font-weight: $header-tagline-font-weight;
			flex-basis: 100%;
		}
}

